import React from "react";
import { ContextProviderComponent } from "src/layouts/Context.js";
import { graphql } from "gatsby";

import GlobalStyle from "src/assets/styles/globalStyles.js";

// import Cookies from "src/components/global/Cookies.js";

const Index = ({ data, children, pageContext }) => {
  return (
    <>
      <ContextProviderComponent>
        <GlobalStyle />

        {children}
      </ContextProviderComponent>
    </>
  );
};

export default Index;
