import { createGlobalStyle } from "styled-components";
import colors from "src/assets/styles/colors.js";

const GlobalStyle = createGlobalStyle`



    *{
        margin: 0;
        padding: 0;
        font-family: "tt-commons-pro", sans-serif;
            font-weight: 300;
            font-style: normal;
        color: ${colors.dark};
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-weight: lighter;
        font-size: 1px;
        border: none;

        @media (min-width: 1440px) {
            /* font-size: 0.0695vw; */

            }
    }

 
    div[data-placeholder-image]{
        background-color: transparent !important;
    }

html {width: 100%; } /* Force 'html' to be viewport width */

 body{
position: relative;
     width: 100vw;
     font-size:  16rem ; 
background-color: ${colors.lightGrey};
    

 }


 
 a, code, h1{
    font-size: 16rem;
 }
 p, span, input{
     font-size: 16rem;
 }
 input::-webkit-input-placeholder {
    font-size: 16rem;
 }
 strong {

    }

    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;

    
        
    }
    button:focus {
        outline: none;
        box-shadow: none;
        }
`;

export default GlobalStyle;
